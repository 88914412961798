<template>
  <section class="doc9">
    <h1 style="font-size: 23px; display: grid; place-items: center;font-family:DFKai-sb;">
      <!--公費生待遇補助經費概算表-->
      衛生福利部補助計畫收支明細表
    </h1>
    <h3>受補助單位: {{ school }}</h3>
    <h3>補助年度:{{ year }}</h3>
    <h3>
      計畫名稱:{{ year }}學年度第{{term}}學期「原住民族及離島地區醫事人員養成計畫」
    </h3>
    <table class="table b-table table-bordered  ">
      <thead>
        <tr>
          <th colspan="2" rowspan="3" style="position: relative">
            <span class="headTxt A">核撥 (結報)</span>
            
            <div class="inclineBorder"></div>
            <span class="headTxt B">經費預算核撥數</span>
            
          </th>
          <td>
            <h6>第一次核撥日期</h6>
            <h6>{{ when }}</h6>
            <h6>金額</h6>
            <h6>$ {{ $formatPrice(map.Fee_5 + map.Fee_6) }} 元</h6>
          </td>
          <td>
            <h6>第二次核撥日期</h6>
            <h6>
              <pre>   年    月     日</pre>
            </h6>
            <h6>金額</h6>
            <h6>
              <pre>$               元</pre>
            </h6>
          </td>
          <td> </td>
        </tr>
        <tr>
          <td> </td>
          <td>
            <h6>第一次餘(絀)數</h6>
            <h6>金額</h6>
            <h6>
              <pre>$               元</pre>
            </h6>
          </td>
          <td>
            <h6>第二次餘(絀)數</h6>
            <h6>金額</h6>
            <h6>
              <pre>$               元</pre>
            </h6>
          </td>
        </tr>
        <tr>
          <td>
            <h6>第一次結報日期</h6>
            <h6>{{ when2 }}</h6>
            <h6>金額</h6>
            <h6>$ {{ $formatPrice(map.Fee_2 + map.Fee_3) }} 元</h6>
          </td>
          <td>
            <h6>第二次結報日期</h6>
            <h6>
              <pre>   年    月     日</pre>
            </h6>
            <h6>金額</h6>
            <h6>
              <pre>$               元</pre>
            </h6>
          </td>
          <td> </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>項目</td>
          <td>核定金額</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>業務費</td>
          <td class="text-right">{{$formatPrice(map.Fee_5)}}</td><!--rowTotal$-device$-->
          <td class="text-right">{{ $formatPrice(map.Fee_3) }}</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>設備費</td>
          <td class="text-right">{{$formatPrice(map.Fee_6)}}</td><!--device$-->
          <td class="text-right">{{ $formatPrice(map.Fee_2) }}</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <!--td>管理費</td>
          <td class="text-right">{{$formatPrice(map.Fee_4)}}</td>
          <td class="text-right">{{ $formatPrice(map.Fee_4) }}</td>
          <td></td>
          <td></td-->
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>小計</td>
          <td class="text-right">{{ $formatPrice(map.Fee_5 + map.Fee_6) }}</td>
          <td class="text-right">{{ $formatPrice(map.Fee_2 + map.Fee_3) }}</td>
          <!-- {{ $formatPrice(map.Surplus) }} -->
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>餘 (絀) 數</td>
          <td></td>
          <td class="text-right">{{$formatPrice(map.Surplus)}}</td><!--rowTotal$-->
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td colspan="2">備註</td>
          <td colspan="4">
            <h6>1.繳回款: 經常門 ${{ $formatPrice(map.Often) }} 元</h6>
            <h6>資本門 ${{ $formatPrice(map.Capital) }} 元</h6>
            <h6></h6>
            <h6>
              2.其他衍生收入: ${{ $formatPrice(map.Other_Income) }} 元, 
              請於結報時併同結餘款解繳本部。
            </h6>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td class="border-0">製表人</td>
          <td class="border-0"></td>
          <td class="border-0">覆核</td>
          <td class="border-0">會計人員</td>
          <td class="border-0">校長<br>(簽約代表人)</td>
        </tr>
      </tfoot>
    </table>
 </section>
</template>

<script>
import queryString from "query-string";
const rowObj = {};
const rows = [
  "業務費",
  //"-公費生受領待遇補助費",
  "設備費",
  "",
  "",
  "小計",
  "餘 (絀) 數",
];
//年月日
export default {
  name: "totalSpend9",
  data() {
    return {
      rows,
      map: {},
      school: "",
      term: "",
      year: "",
      when: "",
      amount: "",
      amount2: "",
      when2: "",
      device$: 0,
      rowTotal$: 0,
    };
  },
  methods: {
    /*async getData() {
      const {
        schoolcode: SchoolCode = "0906",
        byear: Byear = "109",
        term = 1,
        category: Category = "S",
      } = queryString.parse(location.href.split("?")[1]);
      this.term = +term;
      const url = SchoolCode
        ? `Budgeting/Get?schoolcode=${SchoolCode}&byear=${Byear}&term=${term}&category=${Category}`
        : "Budgeting/Get?schoolcode=0906&byear=108&term=1&category=S";
      let map = "";
      //budgetings
      try {
        map = await window.axios.get(url);
        if (Array.isArray(map)) {
          map = map[0];
        }
        if (map) {
          console.log(map.budgetings)
          let bugs = map && map.budgetings ? map.budgetings : [];
          if (!bugs.length) return;
          let self=this;
          bugs.forEach((s, i) => {
            self.device$ += s.Fee_12 ?s.Fee_12 : 0;
            self.rowTotal$ += s.Subtotal;
          });
        } else {
          //alert(`error server api ${url} return 200 but the data is: ` + map);
          const str = `error server api ${url} return status 200 but the data is null`;
          this.$root.$emit("dtc-server-error", str);
          //this.pageRows = [];
          // user can enter data into sheet; not need to empty rows
        }
      } catch (e) {
        this.debug = true;
        const str = e + " API: " + url;
        this.$root.$emit("dtc-server-error", str);
      }
    },*/
  },
  async mounted() {
    //for check is same school-prevent see other school
    const { schoolcode: schoolNow } = queryString.parse(
      location.href.split("?")[1]
    );

    const loginHealthCode = sessionStorage.getItem("darcok").replace(/\"/g, "");

    const stuChoolArr = JSON.parse(sessionStorage.getItem("stuChool"));
    const roleOtalArr = JSON.parse(sessionStorage.getItem("roleOtal"));
    const isSchoolPerson = stuChoolArr.includes(loginHealthCode) ? true : false;

    const isAdminPerson = roleOtalArr.includes(loginHealthCode) ? true : false;

    if (!isSchoolPerson && !isAdminPerson) {
      return;
    }

    if (isSchoolPerson) {
      if (loginHealthCode != schoolNow) {
        return;
      }
    }
    //this.getData();
    //check Can see and then get data
    const {
      schoolcode: SchoolCode = "0003",
      byear: Byear = "109",
      term = 1,
      category: Category = "S",
    } = queryString.parse(location.href.split("?")[1]);
    this.term = term;
    this.year = Byear;
    let schoolArr = await window.axios.get("School/GetAll");
    this.school = schoolArr.find((s) => s.SchoolCode == SchoolCode).SchoolName;
    try {
      this.map = await axios.get(
        `BalanseSheet/Get?schoolcode=${SchoolCode}&byear=${Byear}&term=${term}&category=${Category}`
      );
      
      let ret = "";
      let arr = "";
      let mY = "";
      this.when = `     年    月    日`;
      this.when2 = `     年    月    日`;
      if(this.map.Approve_Date != null)
      {
        ret = this.map.Approve_Date.split("T")[0];
        arr = ret.split("-");
        mY = +arr[0] - 1911;
        this.when = `${mY} 年 ${arr[1]} 月 ${arr[2]} 日`;
      }
      if(this.map.Checkout_Date != null)
      {
        ret = this.map.Checkout_Date.split("T")[0];
        arr = ret.split("-");
        mY = +arr[0] - 1911;
        this.when2 = `${mY} 年 ${arr[1]} 月 ${arr[2]} 日`;
      }

      this.amount = this.$formatPrice(this.map.Approve_Fee);
      this.amount2 = this.$formatPrice(this.map.Checkout_Fee);
      
    } catch (e) {
      alert(e);
    }
  },
};
</script>

<style lang="scss">
.doc9 {
  margin: 0 auto;
  background: white;
  width: 780px;
}
.headTxt{
  position: absolute;
    font-weight: normal !important;
    
    font-size: 16px;
    color: black;
    writing-mode: vertical-lr;
    font-size: 16px;
    height: 95%;
    &.A{
      top: 10px;
    right: 30px;
    }
    &.B{
      top: 5px;
      left: 30px;
      text-align: right;
    }
}
.inclineBorder{
  position: absolute;
    font-weight: normal !important;
    top: -25px;
    left: 105px;
    background: #000;
    writing-mode: vertical-lr;
    rotate: -28deg;
    height: 113%;
    width: 1px;
}
.grid-4-header {
  display: grid;
  background: white;
  grid-template-columns: 180px 600px;
  border: 1px solid black;

  .left,
  .right {
    position: relative;
    background: white;

    >div {
      border: 1px solid black;
    }
  }

  .left::before,
  .left::after {
    position: absolute;
    font-weight: normal !important;
    top: 5px;
    right: 30px;
    font-size: 16px;
    color: black;
    content: "核撥 (結報)";
    writing-mode: vertical-lr;
    font-size: 16px;
    height: 200px;
  }

  .left::after {
    content: "經費預算核撥數";
    top: 220px;
    right: 120px;
  }

  .left {
    display: grid;
    place-items: center;

    >div {
      height: 100%;
      width: 1px;
      box-shadow: 0 10px 0 0 black, 0 -10px 0 0 black;
      transform: rotate(-23deg); //translateX(90px);
    }
  }

  .right {
    display: grid;
    grid-template-columns: repeat(3, 200px);

    >div {
      height: 120px;
      border-top: none;
      border-right: none;
    }
  }
}

h1,
h2 {
  text-align: center;
  font-size: 24px;
}

h2 {
  margin: 1rem 0;
  font-size: 22px;
}

h3 {
  text-align: left;
  margin: 10px 0;
  font-size: 16px;
}

.border-r-1 {
  border-right: 1px solid black !important;
}

.border-b-0 {
  border-bottom: none !important;
}

.grid-4-dtc {
  display: grid;
  grid-template-columns: 181px repeat(3, 200px);
  height: 40px;
  border: 1px solid black;
  border-right: none;
  border-top: none;
  line-height: 40px;

  >div {
    padding-left: 4px;
    border-right: 1px solid black;
  }
}

.grid-2-dtc {
  display: grid;
  grid-template-columns: 181px 600px;
  height: 120px;
  border: 1px solid black;
  border-right: none;
  border-top: none;

  >div {
    padding-left: 4px;
    border-right: 1px solid black;
  }
}

.extra-note {
  padding: 10px;

  >h6:nth-child(2) {
    margin-left: 70px;
    margin-bottom: 25px;
  }
}

footer {
  margin-top: 0rem;
  display: grid;
  padding-left: 10px;
  grid-template-columns: repeat(4, 1fr);
  height: 100px;
}

.f-last {
  position: relative;

  &::after {
    position: absolute;
    top: 18%;
    left: 0;
    width: 120px;
    height: 20px;
    content: "(簽約代表人)";
  }
}
.table-bordered {
  thead,
  tbody {
    th, td {
      border-color: #111 !important;
      border-width: 1px;
      height: 2.5em;
    }
  }
}

@media print {
  @page {
    size: A4 portrait;
  }

  pre {
    border: 0px;
  }
}
//重點科別
</style>
